<template>
  <a
    href="javascript:void(0)"
    class="btn btn-icon btn-light btn-hover-danger btn-sm mx-1"
    v-b-tooltip.hover.top
    :title="$t('TABLES.USER_TOOLTIPS.REVOKE')"
    @click="revokeInviteAlert(user)"
  >
    <span class="svg-icon svg-icon-md svg-icon-danger">
      <inline-svg src="media/svg/icons/Navigation/Close.svg" />
    </span>
  </a>
</template>

<script>
import { mapGetters } from "vuex";
import { REVOKE_SYS_ADMINISTRATOR_INVITE } from "@/core/services/store/core/user.module";
import { REVOKE_CLIENT_USER_INVITE } from "@/core/services/store/core/user.module";

export default {
  name: "RevokeInvite",

  props: ['user', 'type'],

  methods: {
    revokeInviteAlert(user) {
      this.$swal.fire({
        title: `${this.$t("SWEETALERTS.REVOKE_CHALLENGE.TITLE")}`,
        text: user.display_name + " " + `${this.$t("SWEETALERTS.REVOKE_CHALLENGE.MESSAGE")}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `${this.$t("SWEETALERTS.REVOKE_CHALLENGE.CONFIRM")}`,
        cancelButtonText: `${this.$t("GENERAL.TEXT.CANCEL")}`,
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = {};
          let action = null;
          if (this.type == "admin") {
            payload = { userId: user.id };
            action = REVOKE_SYS_ADMINISTRATOR_INVITE;
          } else if (this.type == "client") {
            payload = { userId: user.id, clientId: this.clientInfo.id };
            action = REVOKE_CLIENT_USER_INVITE;
          }
          this.$store.dispatch(action, payload).then(() => {
            this.$swal.fire({
              title: `${this.$t("SWEETALERTS.REVOKE_SUCCESS.TITLE")}`,
              text: user.display_name + ' ' + `${this.$t("SWEETALERTS.REVOKE_SUCCESS.MESSAGE")}`,
              icon: 'success',
              confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
            })            
          })
          .catch(() => {
            this.$swal.fire({
              title: `${this.$t("GENERAL.TEXT.VAGUE_ERROR_TITLE")}`,
              text: `${this.$t("SWEETALERTS.REVOKE_FAIL.MESSAGE")}`,
              icon: 'error',
              confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
            })   
          })
        }
      })
    },
  },

  computed: {
    ...mapGetters(["clientInfo"])
  }
};
</script>
