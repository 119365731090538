<template>
  <div>
    <div class="col-xxl-12 px-0">
      <div
        class="card card-custom card-stretch gutter-b"
        :class="error.active ? 'bordered-card-danger' : 'bordered-card-primary'"
      >
        <div class="card-header border-0 py-5">
          <h3 class="card-title align-items-start flex-column col-xxl-5 px-0">
            <div class="form-group mb-0 col-xxl-10 px-0">
              <input
                id="system_administrator_table_search"
                v-model="search"
                type="text"
                :placeholder="$t('GENERAL.INPUT.SEARCH')"
                class="form-control form-control-solid h-auto py-3 px-5"
                :disabled="loadingComplete == false"
              />
            </div>
          </h3>
          <div class="card-toolbar">
            <ul class="nav nav-pills nav-pills-sm nav-dark-75">
              <li class="nav-item">
                <router-link
                  :to="newUserLink"
                  tag="a"
                  class="btn btn-primary py-2 px-4"
                >
                  {{ $t('GENERAL.BUTTONS.ADD_USER') }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body py-0">
          <div class="table-responsive">
            <table
              id="system_administrator_table"
              class="table table-head-custom table-vertical center table-hover"
              style="table-layout:auto"
            >
              <thead>
                <tr class="text-left">
                  <th class="px-2" style="min-width:300px">
                    {{ $t('TABLES.USER_HEADERS.NAME') }}
                  </th>
                  <th class="px-2" style="min-width:125px">
                    {{ $t('TABLES.USER_HEADERS.STATUS') }}
                  </th>
                  <th class="px-2" style="min-width:175px">
                    {{ $t('TABLES.USER_HEADERS.ACCESS_LEVEL') }}
                  </th>
                  <th class="px-2" style="min-width:100px">
                    {{ $t('TABLES.USER_HEADERS.CREATED_ON') }}
                  </th>
                  <th class="px-2" style="min-width:100px">
                    {{ $t('TABLES.USER_HEADERS.LAST_LOGIN') }}
                  </th>
                  <th class="pr-4 text-right" style="min-width:120px">
                    {{ $t('TABLES.USER_HEADERS.ACTIONS') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(user, i) in computedUserList">
                  <UserRow
                    :key="i"
                    :index="i"
                    :user="user"
                    :type="type"
                    :clientId="clientId"
                  >
                  </UserRow>
                </template>
                <tr v-if="userList.length == 0 && loadingComplete">
                  <td class="px-2 text-center" colspan="7">
                    <span class="text-dark-65 font-weight-bold d-block py-3">
                      {{ $t('TABLES.USER_MESSAGES.NO_USERS_TEXT') }}
                      <router-link
                        tag="a"
                        class="text-primary font-weight-bold"
                        :to="newUserLink"
                      >
                        {{ $t('TABLES.USER_MESSAGES.NO_USERS_LINK') }}
                      </router-link>
                    </span>
                  </td>
                </tr>
                <tr v-if="!loadingComplete && !error.active">
                  <td class="px-2 text-center border-0" colspan="7">
                    <b-spinner
                      variant="primary"
                      label="Spinning"
                      class="table-loader my-3"
                    >
                    </b-spinner>
                  </td>
                </tr>
                <tr v-if="error.active">
                  <td class="px-2 text-center border-0" colspan="7">
                    <b-alert show variant="light">
                      {{ error.message }}
                    </b-alert>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserRow from "@/view/content/users/UserRow.vue";

export default {
  name: "UserTable",

  props: {
    userList: { type: Array, required: true },
    pageLoader: { type: Object, required: true },
    error: { type: Object, required: true },
    type: { type: String, required: true },
    clientId: { type: Number, required: false }
  },

  components: {
    UserRow
  },

  data() {
    return {
      search: ""
    };
  },

  computed: {
    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
          this.pageLoader.componentsRequired &&
        this.error.active == false
      ) {
        return true;
      } else {
        return false;
      }
    },
    newUserLink: function() {
      let link = null;

      if (this.type == "admin") {
        link = "/users/add"
      } else if (this.type == "client") {
        link = "/client/add_user"
      }

      return link;
    },
    computedUserList: function() {
      if (this.search == "") {
        return this.userList;
      } else {
        const self = this;
        let search = this.search.toLowerCase();

        return this.userList.filter(function( user ) {
          let name = user.calculatedName.toLowerCase();
          let email = user.calculatedEmail.toLowerCase();
          let admin_role_name = null;

          if (self.type == "admin") {
            admin_role_name = user.admin_role.name.toLowerCase();
          } else if (self.type == "client") {
            admin_role_name = user.client_role[0].name.toLowerCase();
          };

          return name.includes(search) || email.includes(search) || admin_role_name.includes(search)
        });
      }
    }
  }
};
</script>
