<template>
  <tr
    @mouseover="hovering = true"
    @mouseleave="hovering = false"
  >
    <td class="px-2 py-3 d-table-cell">
      <div class="d-flex justify-content-start">
        <div>
          <UserThumbnail :user="user"></UserThumbnail>
        </div>
        <div class="ml-3">
          <router-link
            tag="a"
            :to="editLink"
            class="text-dark-65 font-weight-bolder text-hover-primary mb-1 font-size-sm"
          >
            <AnonymisedUsername :user="user"></AnonymisedUsername>
          </router-link>
          <router-link
            tag="a"
            :to="editLink"
            class="text-muted font-weight-bold text-muted d-block"
          >
            <AnonymisedEmail :user="user"></AnonymisedEmail>
          </router-link>
        </div>
      </div>
    </td>
    <td class="px-2 py-6 d-table-cell">
      <span>
        <span
          :class="'label label-' + userStatus.class + ' label-dot mr-2 font-size-sm'"
        >
        </span>
        <span
          :class="'font-weight-bold text-' + userStatus.class"
        >
          {{ userStatus.translated_text }}
        </span>
      </span>
    </td>
    <td class="px-2 py-6 d-table-cell">
      <span class="text-dark-65 d-block font-size-sm">
        {{ userRole }}
      </span>
    </td>
    <td class="px-2 py-6 d-table-cell">
      <span class="text-dark-65 d-block font-size-sm">
        <GenericDatetimeDisplay
          :long="true"
          :date="user.created_at"
          :createdAt="true"
          :lastUpdated="false"
          :relative="false"
        >
        </GenericDatetimeDisplay>
      </span>
    </td>
    <td class="px-2 py-6 d-table-cell">
      <span class="text-dark-65 d-block font-size-sm">
        <span v-if="user.last_login == null"> {{ $t("TABLES.USER_PLACEHOLDERS.NOAUTH") }} </span>
        <GenericDatetimeDisplay
          v-else
          :long="true"
          :date="user.last_login"
          :lastUpdated="true"
          :relative="true"
        >
        </GenericDatetimeDisplay>
      </span>
    </td>
    <td class="pr-0 pt-4 text-right d-table-cell">
      <div v-if="hovering" class="px-3">
        <SendPasswordReset
          v-if="userStatus.text == 'Active'"
          :user = user
          :arrayPos = index
        >
        </SendPasswordReset>
        <SuspendUser
          v-if="userStatus.text == 'Active'"
          :user = user
          :arrayPos = index
        >
        </SuspendUser>
        <ActivateUser
          v-if="userStatus.text == 'Blocked'"
          :user = user
          :arrayPos = index
        >
        </ActivateUser>
        <ResendInvite
          v-if="userStatus.text == 'Invited'"
          :user = user
          :clientId="clientId"
        >
        </ResendInvite>
        <RevokeInvite
          v-if="userStatus.text == 'Invited'"
          :user = user
          :type= type
        >
        </RevokeInvite>
      </div>
    </td>
  </tr>
</template>

<script>
import SendPasswordReset from "@/view/components/sweet-alerts/SendPasswordReset.vue";
import SuspendUser from "@/view/components/sweet-alerts/SuspendUser.vue";
import ActivateUser from "@/view/components/sweet-alerts/ActivateUser.vue";
import ResendInvite from "@/view/components/sweet-alerts/ResendInvite.vue";
import RevokeInvite from "@/view/components/sweet-alerts/RevokeInvite.vue";
import UserThumbnail from "@/modules/together-helpers/components/generic-displays/GenericUserThumbnail";
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay"

export default {
  name: "UserRow",

  props: {
    index: { type: Number, required: true },
    user: { type: Object, required: true },
    type: { type: String, required: true },
    clientId: { type: Number, required: false }
  },

  data() {
    return {
      hovering: false
    }
  },

  components: {
    SendPasswordReset,
    SuspendUser,
    ActivateUser,
    ResendInvite,
    RevokeInvite,
    UserThumbnail,
    GenericDatetimeDisplay
  },

  computed: {
    userStatus: function() {
      let calculatedText = "";
      let calculatedClass = "";
      let calculatedTextTranslated = "";

      if (this.user.suspended) {
        calculatedText = "Blocked";
        calculatedClass = "danger";
        calculatedTextTranslated = `${this.$t("GENERAL.STATUS.BLOCKED")}`;
      } else if (this.user.setup_token) {
        calculatedText = "Invited";
        calculatedClass = "warning";
        calculatedTextTranslated = `${this.$t("GENERAL.STATUS.INVITED")}`;
      } else {
        calculatedText = "Active";
        calculatedClass = "success";
        calculatedTextTranslated = `${this.$t("GENERAL.STATUS.ACTIVE")}`;
      }

      return {
        text: calculatedText,
        class: calculatedClass,
        translated_text: calculatedTextTranslated
      };
    },
    userRole: function() {
      let role = null;

      if (this.type == "admin") {
        let admin_role = this.user.admin_role.name.toUpperCase().replace(/ /g,"_");
        role = `${this.$t("SYSTEM.ADMIN_ROLE." + admin_role)}`;
      } else if (this.type == "client") {
        let client_role = this.user.client_role[0].name.toUpperCase().replace(/ /g,"_");
        role = `${this.$t("SYSTEM.CLIENT_ROLE." + client_role)}`;
      };

      return role;
    },
    editLink: function() {
      if (this.type == 'admin') {
        return { name: 'edit_user', params: { id: this.user.id } };
      } else {
        return { name: 'edit_client_user', params: { id: this.user.id } };
      }
    }
  }
};
</script>
