<template>
  <a
    href="javascript:void(0)"
    class="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
    v-b-tooltip.hover.bottom
    :title="$t('TABLES.USER_TOOLTIPS.RESET')"
    @click="sendPasswordReset(user)"
  >
    <span class="svg-icon svg-icon-md svg-icon-primary">
      <inline-svg src="media/svg/icons/Communication/Mail.svg" />
    </span>
  </a>
</template>

<script>
import { SEND_USER_RESET } from "@/core/services/store/core/user.module.js";

export default {
  name: "SendPasswordReset",

  props: ['user'],

  methods: {
    sendPasswordReset(user) {

      this.$store.dispatch(SEND_USER_RESET, user.calculatedEmail)
      .then(() => {
        this.$swal.fire({
          title: `${this.$t("SWEETALERTS.RESET_SUCCESS.TITLE")}`,
          text: user.display_name + ' ' + `${this.$t("SWEETALERTS.RESET_SUCCESS.MESSAGE")}`,
          icon: 'success',
          confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
        })
      })
      .catch(err => {
        let errors = err.response.data.errors;
        this.alertFailure(errors);
      });
    },
    alertFailure: function(errors) {
      let type = "generic_failure";
      let translation = "RESET_FAIL";

      if (errors.user_id != undefined) {
        if (errors.user_id.includes("Project does not contain the required settings to send to My Own Audience")) {
          type = "moa_failure";
          translation = "RESET_FAIL_MOA";
        }
      }

      this.$swal.fire({
        title: `${this.$t(`SWEETALERTS.${translation}.TITLE`)}`,
        text: `${this.$t(`SWEETALERTS.${translation}.MESSAGE`)}`,
        icon: 'error',
        showConfirmButton: true,
        confirmButtonText: (type == "generic_failure") ? `${this.$t("GENERAL.TEXT.CONFIRM")}` : `${this.$t("GENERAL.TEXT.FIX")}`
      }).then((result) => {
        if (result.isConfirmed && type == "moa_failure") {
          this.$router.push({ name: "setup_theme", params: { tab: 3 } })
        }
      })
    }
  }
};
</script>
